import React, { createContext, useContext, useState } from 'react';
import { Box } from 'rebass';
import posed from "react-pose";
import BackgroundImage from '../background';
import theme from "../../gatsby-plugin-theme-ui";
import Links from "./links";

export const MenuContext = createContext({
	menuOpened: false,
	setMenuOpen: () => null,
});

const Container = posed(Box)({
	active: {
		scale: 1,
		delayChildren: 400,
		staggerChildren: 50,
		applyAtStart: { display: 'flex', scale: 0 },
		transition: {
			duration: 400,
			ease: 'linear'
		}
	},
	closed: {
		opacity: 0,
		applyAtEnd: { display: 'none', opacity: 1 },
		transition: {
			duration: 300,
		}
	}
});


const Menu = ({ children, pathname }) => {
	const [menuOpened, setMenuOpen] = useState(false);

	return (
		<MenuContext.Provider
			value={{
				menuOpened,
				setMenuOpen
			}}
		>
			<Container
				pose={menuOpened ? 'active' : 'closed'}
				as="nav"
				sx={{
					display: 'none',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					position: 'fixed',
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
					zIndex: 6,
					transformOrigin:  `${theme.space[3]}px ${theme.space[3]}px`,
				}}
				onClick={() => setMenuOpen(false)}
			>
				<BackgroundImage />
				<Links
					onClick={
						(to, delay) => { setTimeout(() => setMenuOpen(false), delay) }
					}
					sx={{
						flexGrow: 1,
					}}
				/>
			</Container>
			{children}
		</MenuContext.Provider>
	)
};

export default Menu;

export const useMenu = () => useContext(MenuContext);
