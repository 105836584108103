import React from 'react';
import { Box } from 'rebass';
import 'wysiwyg.css';

const Markup = React.forwardRef(({ html = '', sx, ...props }, ref) => {
	return  (
		<Box
			width="100%"
			height="100%"
			{...props}
			ref={ref}
			dangerouslySetInnerHTML={{ __html: html }}
			className="wysiwyg"
			sx={{
				...sx,
				'a': {
					borderBottom: '1px solid'
			}}}
		/>
	)
});

export default Markup;
