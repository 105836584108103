import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box } from 'rebass';

const Link = ({ children, sx, ...props }) =>
	<Box
		as={GatsbyLink}
		sx={{
			color: 'text',
			mx: 2,
			textTransform: 'uppercase',
			fontWeight: 'bold',
			position: 'relative',
			':after': {
				content: "''",
				position: 'absolute',
				left: 0,
				bottom: '-4px',
				height: 1,
				width: '100%',
				transform: 'scaleX(0)',
				transformOrigin: '0 center',
				transition: 'transform 0.3s ease-in-out',
				bg: 'text'
			},
			'&:hover:after, &:focus:after': {
				transform: 'scaleX(1)',
			},
			...sx }}
		{...props}
	>
		{children}
	</Box>;

export default Link;