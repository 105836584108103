import { transparentize } from "@theme-ui/color";

export const colors = {
	black: '#1A1919',
	blackest: '#000000',
	white: '#ffffff',
	lightGrey: '#f5f5f5',
	grey: '#d7d7d7',
	red: '#F00',
};

export const oppositeColors = {
	white: 'black',
	text: 'background',
	black: 'white',
	background: 'text'
};

export const placeholderStyles = {
	color: transparentize('text', 0.5),
	fontStyle: 'italic',
}

export default {
	colors: {
		...colors,
		text: colors.black,
		background: colors.white,
		bg: colors.lightGrey,
		error: colors.red,
		modes: {
			dark: {
				text: colors.white,
				background: colors.black,
				bg: colors.blackest,
			}
		}
	},
	fonts: {
		body: "Arial, 'Raleway', sans-serif",
		heading: "'Montserrat', 'Playfair Display', serif",
		monospace: "Menlo, monospace",
	},
	fontWeights: {
		body: 400,
		heading: 700,
		bold: 700,
		bolder: 800,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	breakpoints: [
		'750px', '1025px', '1400px'
	],
	fontSizes: [10, 13, 14, 16, 20, 24, 36, 48, 64, 72, 84],
	space: [0, 4, 8, 16, 32, 64, 128, 192, 256, 512],
	buttons: {
		primary: {
			color: 'text',
			bg: 'background',
		},
	},
	boxShadows: {
		'default': '4px 4px 20px 0px rgba(0, 0, 0, 0.7)'
	},
	forms: {
		input: {
			borderRadius: 4,
			'&::-webkit-input-placeholder': placeholderStyles,
			'&:-moz-placeholder': placeholderStyles,
			'&:-ms-input-placeholder': placeholderStyles,
		},
		select: {
			borderRadius: 4,
		},
		textarea: {
			borderRadius: 4,
		},
		label: {},
		radio: {},
		checkbox: {},
	},
}