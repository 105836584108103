import React from 'react';
import { Flex } from 'rebass';
import posed from "react-pose";
import { alpha } from "@theme-ui/color";

const PosedOverlay = posed(Flex)({
	isOpen: {
		opacity: 1,
		applyAtStart: { display: 'flex' },
	},
	isHidden: {
		opacity: 0,
		applyAtEnd: { display: 'none' },
	}
});

const Overlay = ({ children, sx = {}, active, color, ...props }) => {
	return (
		<PosedOverlay
			pose={active ? 'isOpen' : 'isHidden'}
			sx={{
				position: 'fixed',
				left: 0,
				top: 0,
				height: '100vh',
				width: '100vw',
				zIndex: 24,
				backgroundColor: alpha(color || 'background', 0.8),
				...sx,
			}}
			{...props}
		>
			{children}
		</PosedOverlay>
	);
};

export default Overlay
