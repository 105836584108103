export default () => {
	const body = document.querySelector('body');
	return {
		enable() {
			body.style.overflow = 'hidden';
		},
		disable() {
			body.style.overflow = 'visible';
		}
	};
}