import React, { forwardRef, useEffect, useState } from 'react';
import { Box } from 'rebass';
import theme from '../../gatsby-plugin-theme-ui';
import posed, { PoseGroup } from "react-pose";
import { useColorMode } from "theme-ui";
import { mapObjIndexed, pipe } from "ramda";

export const THEME_TRANSITION_DURATION = 2000;

const borders = [
	{
		key: 'topLeft',
		width: '50vw',
		height: [theme.space[2], null, theme.space[3]],
		sx: { top: 0, left: 0, transform: 'skewX(45deg)', transformOrigin: 'top left !important', zIndex: 6 },
		createAnimation: ({ fromColor, toColor }) => ({
			exit: { background: `linear-gradient(270deg, ${toColor} 0%, ${toColor} 0%, ${fromColor} 0%)`, },
			enter: { background: `linear-gradient(270deg, ${toColor} 0%, ${toColor} 102%, ${fromColor} 102%)`, },
		})
	},
	{
		key: 'left',
		width: [theme.space[2], null, theme.space[3]],
		height: '100vh',
		sx: { top: 0, left: 0, transform: 'skewY(-45deg)', transformOrigin: 'top left !important', zIndex: 5 },
		createAnimation: ({ fromColor, toColor }) => ({
			exit: { background: `linear-gradient(0deg, ${fromColor} 0%, ${fromColor} 102%, ${toColor} 102%)`, },
			enter: { background: `linear-gradient(0deg, ${fromColor} 0%, ${fromColor} 0%, ${toColor} 0%)` },
		})
	},
	{
		key: 'bottom',
		width: '100vw',
		height: [theme.space[2], null, theme.space[3]],
		sx: { bottom: 0, left: 0, transform: 'skewX(45deg)', transformOrigin: 'bottom left !important', zIndex: 4 },
		createAnimation: ({ fromColor, toColor }) => ({
			exit: { background: `linear-gradient(90deg, ${toColor} 0%, ${toColor} 0%, ${fromColor} 0%)`, },
			enter: { background: `linear-gradient(90deg, ${toColor} 0%, ${toColor} 102%, ${fromColor} 102%)`, },
		})
	},
	{
		key: 'right',
		width: [theme.space[2], null, theme.space[3]],
		height: '100vh',
		sx: { top: 0, right: 0, transform: 'skewY(-45deg)', transformOrigin: 'bottom right !important', zIndex: 3 },
		createAnimation: ({ fromColor, toColor }) => ({
			exit: { background: `linear-gradient(0deg, ${toColor} 0%, ${toColor} 0%, ${fromColor} 0%)`, },
			enter: { background: `linear-gradient(0deg, ${toColor} 0%, ${toColor} 102%, ${fromColor} 102%)`, },
		})
	},
	{
		key: 'topRight',
		width: '50vw',
		height: [theme.space[2], null, theme.space[3]],
		sx: { top: 0, right: 0, transform: 'skewX(45deg)', transformOrigin: 'top right !important', zIndex: 2 },
		createAnimation: ({ fromColor, toColor }) => ({
			exit: { background: `linear-gradient(270deg, ${toColor} 0%, ${toColor} 0%, ${fromColor} 0%)`, },
			enter: { background: `linear-gradient(270deg, ${toColor} 0%, ${toColor} 102%, ${fromColor} 102%)`, },
		})
	},
];

const colors = {
	default: { fromColor: theme.colors.black, toColor: theme.colors.white },
	dark: { fromColor: theme.colors.white, toColor: theme.colors.black },
};

const borderComponents = pipe(
	mapObjIndexed(
		({ fromColor, toColor }) => (
			borders.map(
				({ createAnimation, ...rest }) => posed(
					forwardRef((props, ref) => <Box ref={ref} {...rest} {...props} />)
				)(mapObjIndexed(
					(state, key) => ({
						...state,
						transition: { duration: key === 'enter' ? THEME_TRANSITION_DURATION / 5 : 0, ease: 'linear' },
						delay: key === 'enter' ? ({ i }) => i * (THEME_TRANSITION_DURATION / 5) : 0,}),
					createAnimation({ fromColor, toColor }))))
		)
	)
)(colors);

const Border = () => {
	const [colorMode] = useColorMode();
	const [disabled, setDisabled] = useState(true);
	const [inProgress, setInProgress] = useState(false);

	useEffect(() => {
		setInProgress(true);
		const timeout = setTimeout(() => setInProgress(false), THEME_TRANSITION_DURATION);
		return () => void clearTimeout(timeout);
	}, [colorMode]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDisabled(false);
		}, 500);
		return () => void clearTimeout(timeout);
	}, [setDisabled]);

	const items = borderComponents[colorMode];
	return (
		<Box
			sx={{
				position: 'fixed',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
				overflow: 'hidden',
				pointerEvents: 'none',
				zIndex: 10,
				'> div': {
					position: 'absolute',
					backgroundColor: 'background',
					opacity: inProgress ? 1 : 0,
				},
				':after': {
					content: "''",
					position: 'absolute',
					top: 0,
					bottom: 0,
					right: 0,
					left: 0,
					borderWidth: [theme.space[2], null, theme.space[3]],
					borderStyle: 'solid',
					borderColor: 'background',
					zIndex: 1,
				}
			}}
		>
			{disabled ?
				null :
				(
					<PoseGroup>
						{items.map((Item, i) => <Item key={`${colorMode}-${i}`} i={i} />)}
					</PoseGroup>
				)}
		</Box>
	)
};

export default Border;