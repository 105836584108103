// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-oracle-js": () => import("./../src/templates/oracle.js" /* webpackChunkName: "component---src-templates-oracle-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agence-js": () => import("./../src/pages/agence.js" /* webpackChunkName: "component---src-pages-agence-js" */),
  "component---src-pages-clients-js": () => import("./../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-pages-saintvalentin-js": () => import("./../src/pages/saintvalentin.js" /* webpackChunkName: "component---src-pages-saintvalentin-js" */),
  "component---src-pages-tea-time-js": () => import("./../src/pages/tea-time.js" /* webpackChunkName: "component---src-pages-tea-time-js" */)
}

