import React, { useState } from 'react';
import { Flex, Box } from 'rebass';
import { useMenu } from "../menu";
import Link from "../link";
import LoaderLink from "../loaderLink";
import posed from "react-pose";
import { IoIosArrowBack } from "react-icons/io";

const Line = posed(Box)({
	default: {
		height: props => props.height,
		scaleX: props => props.scaleX,
		transformOrigin: '0% 50%',
		top: props => props.top,
		translateY: '-50%',
		rotate: '0deg',
		transition: {
			top: { duration: 300, delay: 300 },
			scaleX: { duration: 300 },
			rotate: { duration: 300 }
		}
	},
	hovered: {
		height: props => props.height,
		scaleX: 1,
		transformOrigin: '0% 50%',
		top: props => props.top,
		translateY: '-50%',
		rotate: '0deg',
		transition: {
			top: { duration: 300, delay: 300 },
			scaleX: { duration: 300 },
			rotate: { duration: 300 }
		}
	},
	active: {
		height: 3,
		scaleX: props => props.scaleX === 1 ? 1 : 0,
		top: '50%',
		translateY: '-50%',
		rotate: props => props.rotate,
		transformOrigin: '50% 50%',
		transition: {
			top: { duration: 300 },
			scaleX: { duration: 300 },
			rotate: { delay: 300, duration: 300 }
		}
	},
});

const lineSx = { borderRadius: 1, width: '100%', bg: 'text', position: 'absolute', left: 0, };

const BurgerButton = ({ isActive, ...props }) => {
	const [hovered, setHovered] = useState(false);
	const pose = isActive ? 'active' : (hovered ? 'hovered' : 'default');
	return (
		<Flex
			as="button"
			width={25}
			height={20}
			p={0}
			m={0}
			flexDirection="column"
			justifyContent="space-between"
			alignItems="stretch"
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			sx={{
				position: 'relative'
			}}
			{...props}
		>
			<Line pose={pose} height={1} scaleX={1} sx={lineSx} top={0} rotate="45ged"/>
			<Line pose={pose} height={2} scaleX={0.65} sx={lineSx} top={'50%'} rotate="0ged"/>
			<Line pose={pose} height={3} scaleX={1} sx={lineSx} top={'100%'} rotate="-45ged"/>
		</Flex>
	)
};

const Header = ({ pathname, isArticle }) => {
	const { setMenuOpen, menuOpened } = useMenu();

	return (
		<Flex
			justifyContent="space-between"
			alignItems="center"
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				background: 'transparent',
				zIndex: 10,
				width: '100%',
				pt: 4,
				px: [isArticle ? 24 : 4, null, 4],
			}}
		>
			{isArticle ? (
				<IoIosArrowBack
					onClick={() => void typeof window !== "undefined" && window.history.go(-1)}
					size={28}
				/>
				) : (
				<BurgerButton
					isActive={menuOpened}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setMenuOpen(o => !o);
					}}
				/>)}
			<Box
				as="nav"
				fontSize={[0]}
				sx={{
					display: ['none', null, 'flex'],
					alignItems: [null, null, 'center'],
				}}>
				<Link as={LoaderLink} to={"/contact"}>contact</Link>
			</Box>
		</Flex>
	)
};

export default Header;
