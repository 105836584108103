export const getModalRoot = () => {
	if (typeof(document) !== "undefined") {
		let modalRoot = document.getElementById('___gatsby__modal-root')
		if (!modalRoot) {
			modalRoot = document.createElement('div');
			modalRoot.setAttribute("id", '___gatsby__modal-root');
			const gatsbyRoot = document.getElementById('___gatsby');
			if (gatsbyRoot) {
				gatsbyRoot.parentNode.appendChild(modalRoot);
			} else {
				document.body.appendChild(modalRoot)
			}
		}
		return modalRoot;
	}
	return null;
};
