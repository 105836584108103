import React, { useEffect, useState } from 'react';
import Modal from "../modal";
import Bar from "../bar";
import { Heading, Text, Flex, Box, Button as RebassButton } from "rebass";
import Button from "../button";
import Input from "../Input";
import axios from 'axios';
import { pathOr } from 'ramda';

const Newsletter = ({ children }) => {
	const [isOpen, setOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [subscribed, setSubscribed] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (submitted && !loading && email) {
			setLoading(true);
			axios.request({
				url: `${process.env.GATSBY_DRUPAL_BASE_URL}/newsletter-subscribe`,
				method: 'post',
				data: {
					mail: email
				},
				auth: {
					username: process.env.GATSBY_DRUPAL_BASIC_AUTH_USERNAME,
					password: process.env.GATSBY_DRUPAL_BASIC_AUTH_PASSWORD
				},
			}).then(({ data }) => {
				if (data) {
					if (data.Status === 'Error' || data.Code === 499) {
						setError(pathOr('default error message', ['text'], data));
					} else if (data.Code === 200) {
						setSubscribed(true);
					}
				}
			}).catch(console.warn)
				.finally(() => {
					setSubmitted(false);
					setLoading(false);
				});
		}
	}, [submitted, email, loading]);

	return(
		<RebassButton
			sx={{
				fontSize: 'inherit',
				fontFamily: 'inherit',
				fontWeight: 'inherit',
				border: 'none',
				background: 'transparent',
				margin: 0,
				padding: 0,
			}}
			onClick={(e) => {
				e.stopPropagation();
				setOpen(true);
			}}
		>
			{children}
			<Modal isOpen={isOpen} handleClose={() => {
				setOpen(false);
				setSubscribed(false);
				setEmail('');
			}}>
				<Flex flexDirection="column" alignItems="center" py={[4]} sx={{ minWidth: [null, null, 640] }}>
					{!subscribed ? (
						<>
							<Heading
								fontSize={[6, null, 7]}
							>
								Newsletter
							</Heading>
							<Bar fontSize={[2, null, 4]} mb={[4]}>
								un p’tit courrier
							</Bar>
							<Text sx={{ textAlign: 'center' }} fontSize={[1]} fontWeight={800} mb={[3]}>
								De temps en temps vous recevrez une newsletter... ou pas!
							</Text>
							<Flex as="form" flexDirection={['column', null, 'row']} alignItems={[null, null, 'center']} onSubmit={e => {
								e.preventDefault();
								e.stopPropagation();
								setSubmitted(true);
							}}>
								<Box>
									<Input
										type="email"
										required
										disabled={loading}
										placeholder="adresse@gmail.com"
										mb={[3, null, 0]}
										mr={[null, null, 3]}
										width={[null, null, 250]}
										onChange={(e) => {
											setEmail(e.target.value);
											setError(null);
										}}
									/>
									{error ? <Text fontSize={1} color="error" mt={1} sx={{ fontStyle: 'italic' }}>{error}</Text>: null}
								</Box>
								<Button type="submit" disabled={loading}>m’inscrire à la newsletter</Button>
							</Flex>
						</>
					) : (
						<>
							<Heading
								fontSize={[6, null, 7]}
							>
								Merci !
							</Heading>
							<Bar fontSize={[2, null, 4]} mb={[4]}>
								on vous recontactera ;)
							</Bar>
						</>
					)}
				</Flex>
			</Modal>
		</RebassButton>
	);
};

export default Newsletter;