import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box } from 'rebass';
import posed from "react-pose";
import Overlay from "./overlay";
import Button from "./button";
import { getModalRoot } from './utils';
import createScrollLock from '../../utils/scroll-lock';
import { useMediaQuery } from "react-responsive";
import theme from "../../gatsby-plugin-theme-ui";

const scrollLock = typeof document === 'object' ? createScrollLock() : null;

const ModalContainer = posed(Box)({
	isOpen: {
		scaleY: 1,
		scaleX: 1,
		y: 0,
		opacity: 1,
		transition: {
			scaleY: {
				duration: 300,
			},
			scaleX: {
				duration: 300,
				delay: 300,
			}
		}
	},
	isHidden: {
		scaleY: 0,
		scaleX: 0.02,
		y: 100,
		opacity: 0,
		transition: {
			y: {
				duration: 200,
				ease: 'easeIn'
			},
			opacity: {
				duration: 200,
				ease: 'linear',
			},
			scaleY: {
				duration: 0,
				delay: 200,
			},
			scaleX: {
				duration: 0,
				delay: 200,
			}
		}
	}
});

const Body = posed(Box)({
	isOpen: {
		opacity: 1,
		transition: {
			default: {
				delay: 600,
				duration: 400,
			}
		}
	},
	isHidden: {
		opacity: 0,
	}
});

const Modal = ({ children, handleClose = () => null, isOpen, renderInPortal = true, color, ...props }) => {
	const modalRoot = useRef(getModalRoot());
	const timeoutRef = useRef(0);
	const [isVisible, setIsVisible] = useState(isOpen);
	const [isHiding, setIsHiding] = useState(false);
	const isTabletOrMobile = useMediaQuery({ maxWidth: theme.breakpoints[1] });

	const handleCloseClick = useCallback((e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		clearTimeout(timeoutRef.current);
		setIsHiding(true);
		setIsVisible(false);
		timeoutRef.current = setTimeout(() => {
			handleClose();
			setIsHiding(false);
			if (isTabletOrMobile) { scrollLock.disable(); }
		}, 250);
	}, [handleClose, setIsHiding, isTabletOrMobile]);

	useEffect(() => {
		if (isOpen && !isVisible && !isHiding) {
			setIsVisible(true);
			if (isTabletOrMobile) { scrollLock.enable(); }
		} else if (!isOpen && isVisible && !isHiding) {
			handleCloseClick();
		}
	} , [isOpen, isVisible, isHiding, setIsVisible, handleCloseClick, isTabletOrMobile]);

	if (typeof window !== 'undefined') {
		const component = (
			<Overlay
				active={isVisible}
				onClick={handleCloseClick}
				justifyContent="center"
				alignItems="center"
				color={color}
				{...props}
			>
				<ModalContainer
					onClick={e => {
						e.stopPropagation();
					}}
					pose={isVisible ? 'isOpen' : 'isHidden'}
					p={4}
					sx={{
						position: 'relative',
						backgroundColor: color || 'background',
						minHeight: 200,
						maxWidth: '90vw',
						maxHeight: '100vh',
						overflow: 'auto'
					}}
				>
					<Button onClick={handleCloseClick}/>
					<Body>
						{children}
					</Body>
				</ModalContainer>
			</Overlay>
		);

		return renderInPortal ?
			ReactDOM.createPortal(component, modalRoot.current) :
			component;
	}
	return null;
};

export default Modal;
