import React, { useEffect } from "react";
import { Box } from "rebass";
import { useColorMode } from 'theme-ui';
// import Cursor from '../components/cursor';
import Logo from '../components/logo';
import Border from '../components/border';
import Header from '../components/header';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu, { useMenu } from "../components/menu";
import CookieBanner from "../components/cookieBanner";
import LoaderLink from '../components/loaderLink';

const Layout = ({ children, location: { pathname }, pathContext: { isArticle } }) => {
	const [colorMode, setColorMode] = useColorMode();
	const { setMenuOpen, menuOpened } = useMenu();

	// make only the dark theme available
	useEffect(() => {
		if (colorMode !== 'dark') {
			setColorMode('dark');
		}
	}, [colorMode]);

	return (
			<Box sx={{ minHeight: '100vh' }} fontFamily="body">
				<LoaderLink
					to='/'
					onClick={() => !!menuOpened && setMenuOpen(o => !o)}
				>
					<Logo
						colorMode="dark"
						sx={{ cursor: 'pointer' }}
					/>
				</LoaderLink>
				<Border />
				<Header pathname={pathname} isArticle={isArticle} />
				<Box padding={[2, null, 3]} as="main">{children}</Box>
				{/*<Cursor />*/}
				<CookieBanner />
			</Box>
	)
};

// use providers
export default ({ children, ...props }) => (
	<Menu pathname={props.location.pathname}>
		<Layout children={children} {...props} />
	</Menu>
);
