import { Text } from 'rebass';
import React from "react";

const Bar = props =>
	<Text
		{...props}
		sx={{
			position: 'relative',
			display: 'inline-block',
			letterSpacing: '0.1em',
			textTransform: 'uppercase',
			zIndex: 1,
			color: 'background',
			fontFamily: 'heading',
			':after': {
				content: "''",
				position: 'absolute',
				zIndex: -1,
				left: 0,
				right: 0,
				bottom: 0,
				top: 0,
				backgroundColor: props.bgColor,
				transform: `rotate(${props.rotate}deg)`,
			},
			...props.sx
		}}
	/>

Bar.defaultProps = {
	rotate: 1,
	fontSize: [3],
	py: [1],
	px: [2],
	mt: [-1],
	bgColor: 'text',
};

export default Bar;