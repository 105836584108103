import React, { useMemo } from 'react';
import { Box, Flex } from 'rebass';
import posed from "react-pose";
import LoaderLink from "../loaderLink";
import Newsletter from "../newsletter";
import { graphql, StaticQuery } from "gatsby";

const ListItem = posed(Box)({
	active: { opacity: 1 },
	closed: { opacity: 0 },
});

const linksData = [
	{
		drupalUri: 'internal:/agence',
		to: '/agence',
		title: 'Agence',
	},
	{
		drupalUri: 'internal:/clients',
		to: '/clients',
		title: 'Clients',
	},
	{
		// drupalUri: 'internal:/qui-sommes-nous',
		drupalUri: 'internal:/equipe',
		to: '/qui-sommes-nous',
		title: 'Qui sommes-nous ?',
	},
	{
		drupalUri: 'internal:/contact',
		to: '/contact',
		title: 'Contact',
	},
	{
		drupalUri: 'internal:/newsletter',
		component: Newsletter,
		title: 'Newsletter',
	},
	{
		drupalUri: 'internal:/tea-time',
		to: '/tea-time',
		title: 'Tea time',
	},
];

const Line = ({ top, height, bottom }) =>
	<Box as="span" sx={{
		position: 'absolute',
		height,
		width: '100%',
		left: 0,
		bottom,
		top,
		backgroundColor: 'text',
		transform: 'scaleX(0)',
		transition: 'transform 0.6s linear',
	}}/>;

const Links = ({ data: { menu: { links } }, onClick = () => null, ...props }) => {

	const items = useMemo(() => linksData.map(link => ({
		...link,
		...links.find(item => item.link.uri === link.drupalUri),
	})), [links]);

	return (
		<Flex justifyContent="center" alignItems="center" flexDirection="column" {...props}>
			{items.map((link, i) => (
				<ListItem
					key={i}
					fontFamily="heading"
					fontSize={[6, null, null, 7]}
					fontWeight="bold"
					lineHeight={1}
					mb={[3]}
					mt={[2, null, null, 3]}
					sx={{
						zIndex: 1, position: 'relative',
						':hover span': { transform: 'scaleX(1)', transition: 'transform 0.3s ease', ':nth-of-type(2)': { transitionTimingFunction: 'cubic-bezier(0.63, 0.01, 0.25, 1)' } },
						'@media screen and (max-width: 420px)': {
							fontSize: '32px',
						},
						'@media screen and (max-width: 370px)': {
							fontSize: '28px',
						},
						'@media screen and (max-width: 325px)': {
							fontSize: '24px',
						},
					}}
				>
					{link.component ? (
						<link.component>
							<Line top={0} height={2} />
							<Line top="50%" height={3} />
							<Line bottom={0} height={5}/>
							{link.title}
						</link.component>
					) : (
						<LoaderLink
							to={link.to}
							onClick={onClick}
						>
							<Line top={0} height={2} />
							<Line top="50%" height={3} />
							<Line bottom={0} height={5}/>
							{link.title}
						</LoaderLink>
					)}
				</ListItem>
			))}
		</Flex>
	)
};

export default props => (
	<StaticQuery
		query={graphql`
			query LinksQuery {
				menu: allMenuLinkContentMenuLinkContent {
					links: nodes {
						title
						link {
							uri
						}
					}
				}
			}
	`}
		render={data => <Links data={data} {...props}/>}
	/>
);