import React from 'react';
import { Box } from 'rebass';
import ArrowIcon from '../../images/icons/arrow.svg'
import { oppositeColors } from "../../gatsby-plugin-theme-ui";

const borderWidth = '1px';
const borderRadius = '1px';

const Button = ({ children, sx = {}, color = 'text', ...props }) => {
	return (
		<Box
			as="button"
			{...props}
			fontSize={0}
			fontWeight={700}
			bg="transparent"
			color={color}
			sx={{ ...sx,
				textTransform: 'uppercase',
				position: 'relative',
				borderRadius: 0,
				border: 'none',
				padding: 0,
				outline: 'none',
				px: 4,
				py: 12,
				'.arrow': {
					position: 'absolute',
					top: '50%',
					left: 0,
					transform: 'translate(-50%, -50%)',
					'path': {
						fill: color,
					}
				},
				'.arrow, .text:before': {
					transition: 'opacity 0.1s ease-out 0.75s',
				},
				'&:before, .back-border_top, .back-border_bottom': {
					position: 'absolute',
					borderWidth: `${borderWidth}`,
					borderColor: 'transparent',
					borderStyle: 'solid',
					borderRadius,
					width: '0%',
					height: '100%',
					transform: 'scaleY(0)',
					transformOrigin: '0 center',
					boxSizing: 'border-box',
				},
				'&:before': {
					content: "''",
					top: 0,
					left: 0,
					transition: 'width 0.25s ease-out 0.5s, transform 0.25s ease-out 0.75s',
					borderTopColor: color,
					borderBottomColor: color,
					borderLeftColor: color,
				},
				'.back-border_top, .back-border_bottom': {
					left: 0,
					width: '100%',
					height: '100%',
					transition: 'transform 0.5s ease-out',
					borderRightColor: color,
				},
				'.back-border_top': {
					top: 0,
					transformOrigin: '0 top',
				},
				'.back-border_bottom': {
					bottom: 0,
					transformOrigin: '0 bottom',
				},
				':focus, :hover, :active': {
					'.arrow, .text:before': {
						opacity: 0,
						transitionDelay: '0.5s'
					},
					'&:before': {
						transform: 'scaleY(1)',
						width: '100%',
						transition: 'transform 0.25s ease-out, width 0.25s ease-out 0.25s',
					},
					'.back-border_top, .back-border_bottom': {
						transition: 'transform 0.5s ease-out 0.5s',
						borderRightColor: color,
						transform: 'scaleY(1)'
					},
				},
				':active': {
					color: oppositeColors[color],
					bg: color,
				}
			}}
		>
			<span className="back-border_top" />
			<span className="back-border_bottom" />
			<ArrowIcon strokeWidth={2} size={13} className="arrow"/>
			<Box
				as="span"
				className="text"
				sx={{
					display: 'inline-block',
					position: 'relative',
					py: '6px',
					'&:before': {
						content: "''",
						position: 'absolute',
						bottom: 0,
						left: 0,
						width: 10,
						height: 1,
						bg: color,
					},
				}}
			>
				{children}
			</Box>
		</Box>
	);
}

export default Button;
