import React, { useState } from 'react';
import { Box } from 'rebass';
import BlackSvg from '../../images/icons/logo-black.svg';
import WhiteSvg from '../../images/icons/logo-white.svg';
import LogoGif from '../../assets/logo.gif';
import { useColorMode } from "theme-ui";
import posed from "react-pose";
import { useMediaQuery } from "react-responsive";
import theme from "../../gatsby-plugin-theme-ui";

const LogoBlack = posed(Box)({
	leftDefault: {
		clipPath: 'polygon(0% 0%, 105% 0%, 105% 105%, 0% 105%)'
	},
	leftDark: {
		clipPath: 'polygon(0% 0%, 105% 0%, 105% 0%, 0% 0%)'
	},
	hoveredDefault: {
		clipPath: 'polygon(0% 105%, 105% 105%, 105% 105%, 0% 105%)'
	},
	hoveredDark: {
		clipPath: 'polygon(0% 0%, 105% 0%, 105% 105%, 0% 105%)'
	}
});

const Logo = ({ sx = {}, colorMode, ...props }) => {
	// const [colorMode, setColorMode] = useColorMode();
	const [hovered, setHovered] = useState(false);

	const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: theme.breakpoints[1] });

	return (
		<Box
			width={60}
			height={60}
			as="button"
			sx={{
				position: 'fixed',
				transform: 'translateX(-50%)',
				fontSize: 0,
				top: 0,
				left: '50%',
				border: 'none',
				zIndex: 35,
				outline: 'none',
				'> div': {
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					':first-of-type': {
						zIndex: 1
					},
					':last-of-type': {
						zIndex: 2
					},
					'svg': {
						height: '100%',
						width: '100%',
					}
				},
				...sx
			}}
			// onClick={() => {
			// 	setColorMode(colorMode === 'default' ? 'dark' : 'default')
			// }}
			// onMouseEnter={() => !isTabletOrMobileDevice ? setHovered(true) : null}
			// onMouseLeave={() => !isTabletOrMobileDevice ? setHovered(false) : null}
			{...props}
		>
			<Box>
				<WhiteSvg />

				{!props.hideGif && (
					<img
						src={LogoGif}
						style={{ position: 'absolute', left: 0 }}
						onError={(e) => {
							e.target.style.display = 'none';
						}}
					/>
				)}
			</Box>
			{/*<LogoBlack pose={`${hovered ? 'hovered' : 'left'}${colorMode === 'default' ? 'Default' : 'Dark'}`}>*/}
			{/*	<BlackSvg />*/}
			{/*</LogoBlack>*/}
		</Box>
	)
};

export default Logo;
