import React from 'react';
import { Box } from 'rebass';
import { useColorMode } from "theme-ui";
import posed from 'react-pose';
import black from '../../video/bg_black.mp4';
import white from '../../video/bg_white.mp4';
import bgMobile from '../../video/bg_mobile.mp4';
import bgDesktop from '../../video/bg_desktop.mp4';
import ReactPlayer from 'react-player';

const Default = posed(Box)({
	default: {
		opacity: 1,
	},
	disabled: {
		opacity: 0,
		delay: 300
	}
});

const Dark = posed(Box)({
	dark: {
		opacity: 1,
	},
	disabled: {
		opacity: 0,
		delay: 300
	}
});

const BackgroundImage = () => {
	const [color] = useColorMode();

	return (
		<Box
			width="100%"
			height="100%"
			sx={{
				position: 'absolute', top: 0, left: 0, '> div': { position: 'absolute' },
				video: {
					objectFit: 'cover',
					borderRadius: '1px',

					/* Old shadow dom for play button */

					'*::-webkit-media-controls-play-button': {
						display: 'none!important',
						WebkitAppearance: 'none',
					},

					/* New shadow dom for play button */

					'*::-webkit-media-controls-start-playback-button': {
						display: 'none!important',
						WebkitAppearance: 'none',
					}
				}
			}}
		>
			<Default pose={color === 'default' ? 'default' : 'disabled'} bg="background" padding={[2, null, 3]} width="100%"
							 height="100%">
				<ReactPlayer
					url={white}
					loop
					playsinline
					playing
					muted
					width="100%"
					height="100%"
					config={{
						file: {
							attributes: {
								id: 'videoId',
								preload: 'auto',
							},
						},
					}}
				/>
			</Default>
			<Dark pose={color === 'dark' ? 'dark' : 'disabled'} bg="background" padding={[2, null, 3]} width="100%"
						height="100%">
				<ReactPlayer
					url={black}
					loop
					playsinline
					playing
					muted
					width="100%"
					height="100%"
					config={{
						file: {
							attributes: {
								id: 'videoId',
								preload: 'auto',
							},
						},
					}}
				/>
			</Dark>
		</Box>
	);
};

export default BackgroundImage
