import React, { useEffect, useState } from 'react';
import { Flex, Heading } from 'rebass';
import { graphql, StaticQuery } from "gatsby";
import Modal from "../modal";
import Markup from "../markup";
import Button from "../button";
import Cookies from 'js-cookie';

export const COOKIE_KEY = 'BELAZAR_COOKIE';
export const COOKIE_ACCEPTED_VALUE = 'COOKIE_ACCEPTED';
export const COOKIE_DENIED_VALUE = 'COOKIE_DENIED';

export const isCookieAccepted = () => Cookies.get(COOKIE_KEY) === COOKIE_ACCEPTED_VALUE;

const CookieBanner = ({ sx, config, ...props }) => {
	const { acceptLabel, denyLabel, description, title } = config;
	const [modalActive, setModalActive] = useState(false);

	useEffect(() => {
		if (!Cookies.get(COOKIE_KEY)) {
			const timeout = setTimeout(() => void setModalActive(true), 300);
			return () => clearTimeout(timeout);
		}
	}, []);

	return (
		<Modal
			isOpen={modalActive}
			sx={{ ...sx }}
			handleClose={() => setModalActive(false)}
			{...props}
		>
			<Flex
				width={['100%', null, 650]}
				minHeight={[null, null, 300]}
				py={3}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				sx={{ textAlign: 'center' }}
			>
				<Heading fontSize={[6, null, 7]} mb={3}>
					{title}
				</Heading>
				<Markup maxWidth={610}  html={description.processed} />
				<Flex
					mt={[3, null, 3]}
					justifyContent="space-between"
					flexDirection={['column', null, 'row']}
					maxWidth={500}
					width={[null, null, 1]}
				>
					<Button onClick={() => {
						setModalActive(false);
						Cookies.set(COOKIE_KEY, COOKIE_ACCEPTED_VALUE);
					}}
						mb={[2, null, 0]}
					>
						{acceptLabel}
					</Button>
					<Button onClick={() => {
						setModalActive(false);
						Cookies.set(COOKIE_KEY, COOKIE_DENIED_VALUE);
					}}>
						{denyLabel}
					</Button>
				</Flex>
			</Flex>
		</Modal>
	);
}

const query = graphql`
    query CookieBannerQuery {
        config: configPagesCookieBanner {
            acceptLabel: field_accept_cookie_label
            denyLabel: field_deny_cookie_label
            description: field_description {
                processed
            }
            title: field_titre
        }
    }
`;

export default props => (
	<StaticQuery
		query={query}
		render={data => data.config ? <CookieBanner config={data.config} {...props} /> : null}
	/>
);
