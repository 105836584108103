import React from 'react';
import { Input as RebassInput } from '@rebass/forms';
import { transparentize } from "@theme-ui/color";

const Input = ({ sx, color = 'text', ...props}) => {
	const placeholderStyles = {
		color: transparentize(color, 0.5),
	};

	return (
		<RebassInput
			{...props}
			sx={{
				fontSize: 1,
				borderColor: transparentize(color, 0.8),
				'&::-webkit-input-placeholder': placeholderStyles,
				'&:-moz-placeholder': placeholderStyles,
				'&:-ms-input-placeholder': placeholderStyles,
				...sx,
			}}
		/>
	)
};

export default Input;
