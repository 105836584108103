import posed from "react-pose";
import { Box } from "rebass";
import React from "react";

const VerticalLine = posed(Box)({
	isOpen: {
		scaleY: 1,
		transition: {
			default: {
				delay: 600,
				duration: 200,
			}
		}
	},
	isHidden: {
		scaleY: 0,
		transition: {
			default: {
				delay: 250,
				duration: 0,
			}
		}
	}
});

const HorizontalLine = posed(Box)({
	isOpen: {
		scaleX: 1,
		transition: {
			default: {
				delay: 800,
				duration: 200,
			}
		}
	},
	isHidden: {
		scaleX: 0,
		transition: {
			default: {
				delay: 250,
				duration: 0,
			}
		}
	}
});

const Button = ({ isOpen, ...props }) => (
	<Box
		as="button"
		p={0}
		m={0}
		sx={{
			position: 'absolute',
			right: 3,
			top: 3,
			height: 33,
			width: 33,
			transform: 'rotate(45deg)'
		}}
		{...props}
	>
		<VerticalLine
			as="span"
			pose={isOpen ? 'isOpen' : 'isHidden'}
			sx={{ position: 'absolute', backgroundColor: 'text', height: '100%', width: 1, left: '16px', top: 0 }}
		/>
		<HorizontalLine
			as="span"
			pose={isOpen ? 'isOpen' : 'isHidden'}
			sx={{ position: 'absolute', backgroundColor: 'text', width: '100%', height: 1, top: '16px', left: 0 }}/>
	</Box>
);

export default Button;